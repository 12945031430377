import { ColumnPattern, RowPattern } from "./seating-block";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const alphabetWithoutI = "ABCDEFGHJKLMNOPQRSTUVWXYZ";

export interface SeatInfo {
  row: string;
  seat: string;
}
export class SeatNumberGenerator {
  rowPattern: RowPattern;
  columnPattern: ColumnPattern;
  manualRowPattern: string;
  manualColumnPattern: string;
  manualRowPatternSegments: string[] = [];
  manualColumnPatternSegments: string[] = [];

  rows: number;
  columns: number;
  currentRow = 0;
  currentColumn = 0;
  lastResult: SeatInfo | undefined;

  private generatedRows: string[] = [];
  private generatedSeats: string[] = [];

  constructor(
    rowPattern: RowPattern,
    seatPattern: ColumnPattern,
    rows: number,
    columns: number,
    manualRowPattern = "",
    manualColumnPattern = "",
  ) {
    this.rowPattern = rowPattern;
    this.columnPattern = seatPattern;
    this.rows = rows;
    this.columns = columns;
    this.manualRowPattern = manualRowPattern;
    this.manualColumnPattern = manualColumnPattern;
    this.validate();
  }

  private validate() {
    if (this.manualRowPattern.trim() && this.rowPattern === RowPattern.MANUAL) {
      this.manualRowPatternSegments = this.manualRowPattern.split(",");
      if (this.manualRowPatternSegments.length !== Number(this.rows)) {
        throw new Error(
          `Needed ${this.rows} row labels, got ${this.manualRowPatternSegments.length}`,
        );
      }
    }
    if (
      this.manualColumnPattern.trim() &&
      this.columnPattern === ColumnPattern.MANUAL
    ) {
      this.manualColumnPatternSegments = this.manualColumnPattern.split(",");
      if (this.manualColumnPatternSegments.length !== Number(this.columns)) {
        throw new Error(
          `Needed ${this.columns} seat labels, got ${this.manualColumnPatternSegments.length}`,
        );
      }
    }
  }

  next(): SeatInfo {
    const rowResult = this.createRowResult();
    const columnResult = this.createColumnResult();
    if (this.currentColumn === this.columns - 1) {
      this.currentColumn = 0;
      this.currentRow = this.currentRow + 1;
    } else {
      this.currentColumn++;
    }
    this.generatedRows.push(`${rowResult}`);
    this.generatedSeats.push(`${columnResult}`);
    return { row: `${rowResult}`, seat: `${columnResult}` };
  }

  getRowLabels() {
    return [...new Set(this.generatedRows)];
  }

  getColumnLabels() {
    return [...new Set(this.generatedSeats)];
  }

  private createRowResult() {
    if (this.rowPattern === RowPattern.NUMERIC) {
      return this.currentRow + 1;
    } else if (this.rowPattern === RowPattern.NUMERIC_REVERSE) {
      return this.rows - this.currentRow;
    } else if (this.rowPattern === RowPattern.A_Z) {
      return alphabet.substring(this.currentRow, this.currentRow + 1);
    } else if (this.rowPattern === RowPattern.A_Z_WITHOUT_I) {
      return alphabetWithoutI.substring(this.currentRow, this.currentRow + 1);
    } else if (this.rowPattern === RowPattern.Z_A) {
      return alphabet.substring(
        this.rows - this.currentRow - 1,
        this.rows - this.currentRow,
      );
    } else if (this.rowPattern === RowPattern.Z_A_WITHOUT_I) {
      return alphabetWithoutI.substring(
        this.rows - this.currentRow - 1,
        this.rows - this.currentRow,
      );
    } else if (this.rowPattern === RowPattern.MANUAL) {
      return this.manualRowPatternSegments[this.currentRow];
    }
    throw new Error("Unsupported row/column patterns");
  }

  private createColumnResult() {
    if (this.columnPattern === ColumnPattern.NUMERIC) {
      return this.currentColumn + 1;
    } else if (this.columnPattern === ColumnPattern.NUMERIC_REVERSE) {
      return this.columns - this.currentColumn;
    } else if (this.columnPattern === ColumnPattern.A_Z) {
      return alphabet.substring(this.currentColumn, this.currentColumn + 1);
    } else if (this.columnPattern === ColumnPattern.A_Z_WITHOUT_I) {
      return alphabetWithoutI.substring(
        this.currentColumn,
        this.currentColumn + 1,
      );
    } else if (this.columnPattern === ColumnPattern.Z_A) {
      return alphabet.substring(
        this.columns - this.currentColumn - 1,
        this.columns - this.currentColumn,
      );
    } else if (this.columnPattern === ColumnPattern.Z_A_WITHOUT_I) {
      return alphabetWithoutI.substring(
        this.columns - this.currentColumn - 1,
        this.columns - this.currentColumn,
      );
    } else if (this.columnPattern === ColumnPattern.MANUAL) {
      return this.manualColumnPatternSegments[this.currentColumn];
    }
    throw new Error("Unsupported row/column patterns");
  }
}
