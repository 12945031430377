import { FederatedPointerEvent, Text, TextStyle } from "pixi.js";
import _ from "lodash";
import { setDraggable } from "@utils/seating-map/make-draggable";
import { ActionButton } from "@utils/seating-map/action-button";

export class Label extends Text {
  type = "Label";

  constructor(
    text: string,
    style: Partial<TextStyle>,
    options?: { make_draggable: boolean; allow_delete: boolean },
  ) {
    super(text, style);
    if (options?.make_draggable) {
      setDraggable(this);
    }
    if (options?.allow_delete) {
      this.addDeleteButton();
    }
  }

  toJson() {
    return {
      x: this.x,
      y: this.y,
      text: this.text,
      type: "Label",
      style: _.pick(this.style, ["fontFamily", "fontSize", "fill", "align"]),
    };
  }

  addDeleteButton() {
    const deleteButton = new ActionButton("X", {
      fontFamily: "Arial",
      fontSize: 10,
      fill: 0x000000,
      align: "center",
    });
    deleteButton.x = 0;
    deleteButton.y = -10;
    deleteButton.interactive = true;
    deleteButton.on("pointerdown", (e: FederatedPointerEvent) => {
      e.stopPropagation();
      this.parent.removeChild(this);
    });
    this.addChild(deleteButton);
  }
}
